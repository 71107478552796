<template>
  <div class="submitQualification">
    <a-modal
      :width="606"
      :visible="qualificationvisible"
      :footer="false"
      :closable="false"
    >
      <div class="modal_box">
        <div class="box_con">
          <div class="warnMess">
            <img src="@/assets/image/knowledgeMall/success.png" alt="">
            <p>提示:<span v-if="tipsText" v-html="tipsText"></span><span v-else>{{titleMessage}}</span></p>
          </div>
          <p class="submitTitle">提交资质</p>
          <div class="formItem">
            <p class="title">相关资质：</p>
            <a-upload
              name="avatar"
              list-type="picture-card"
              class="avatar-uploader"
              :show-upload-list="false"
              :before-upload="beforeUpload"
              :customRequest="handleChange"
            >
              <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
              <div v-else class="uploadStyle">
                <img src="@/assets/image/knowledgeMall/camero.png" alt="">
                <p>上传图片</p>
              </div>
            </a-upload>
          </div>
          <!-- <div class="formItem">
            <p class="title">资质类型：</p>
            <a-select ref="select" placeholder="选择资质类型" style="width: 305px" v-model="type">
              <a-select-option
                v-for="(item, index) in certifList"
                :key="index"
                :value="item.tempId"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </div> -->
          <div class="formItem">
            <p class="title">补充说明：</p>
            <a-textarea style="width: 305px"
              v-model="textarea"
              :auto-size="{ minRows: 2, maxRows: 6 }"
            />
          </div>
          <div class="formItem">
            <p class="title">证书编号：</p>
            <a-input style="width: 305px"
              v-model="certificateCode"
              placeholder="请输入证书编号"
            />
          </div>
          <div class="formItem">
            <p class="title"></p>
            <a-upload
              name="file"
              :remove="removeFile"
              :fileList="fileList"
              :multiple="false"
              :customRequest="uploadFile"
              accept=".doc, .docx, .pdf, .xls, .xlsx"
            >
              <div class="uploadFile">
                <a-spin class="load" v-show="loading" size='large' />
                <a-icon type="cloud-upload" style="color:#15B7DD" /> 点击上传文件
              </div>
            </a-upload>
          </div>
        </div>
        <div class="box_button">
          <div class="all-btn-blue" @click="onCancel()">取消</div>
          <div class="all-btn-small" @click="onConfirm()">提交</div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { uploadObject } from "@/unit/cosUpload.js";
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: [
    'tipsText',
    'courseId',
    'qualificationvisible',  // 控制弹窗显示/隐藏  必填
  ],
  // 数据对象
  data () {
    return {
      loading: false,
      titleMessage: '', // 提示信息
      imageUrl:'',
      certifList: [],
      certificateCode:'', // 证书编号
      type: undefined,
      textarea: '',
      fileSrc: '',
      fileName: '',
      fileList: []
    }
  },
  // 事件处理器
  methods: {
    // 取消
    onCancel() {
      if(this.loading){
        return 
      }
      this.$emit('onCancel')
    },
    // 确定
    onConfirm() {
      if(this.loading){
        return 
      }
      // if (!this.certificateCode) {
      //   return this.$message.warning('请填写证书编号');
      // }
      let data = {
        imageUrl: this.imageUrl,
        type: this.type,
        textarea: this.textarea,
        fileSrc: this.fileSrc,
        code:this.certificateCode, //
        fileName: this.fileName

      }
      this.$emit('onConfirm',data)
    },

    // 文件上传更新进度和单文件上传初始化
    fileProgressCallback(progress, speed, name) {
      /*
       * progress 进度
       * speed 传输速度
       * name 文件名称
       * */
    },

    // 上传图片
    handleChange(info) {
      // 腾讯云对象存储上传文件
      const bucket = 'certificate/exam/' // *必填 文件存储地址（地址参考cosUpload.js说明文件）

      uploadObject(info.file, bucket, this.fileProgressCallback,(url, fileName) => {
        // 上传完成
        if (url) {
          this.$message.success('上传成功');
          this.imageUrl = url;
        } else {
          this.$message.error("上传失败");
        }
      });
    },
    // 上传文件
    uploadFile(info){
      console.log(info,this.fileList)
      this.loading = true;
      // if(info.fileList.length>1){
      //   info.fileList.splice(0,1)
      // }
      // if (info.file.status === 'done') {
        let file = info.file;
        this.$upload({
          file: file,
          progress: (e)=>{
            // this.$message.error('上传失败');
            // this.loading = false;
          },
          success: (e)=>{
            this.$message.success('上传成功');
            this.loading = false;
            this.fileName = info.file.name;
            this.fileSrc = e;
            this.fileList = [info.file];
            // 进度条
            // let progress = { percent: 1, status: 'success' }
            // let speed = 100/(info.file.size/65000)//上传速度
            // const intervalId = setInterval(() => {
            //   // debugger
            //   // 控制进度条防止在未上传成功时进度条达到100
            //   if (progress.percent < 99 && progress.percent+speed < 100 ) {
            //     progress.percent+=speed//控制进度条速度
            //     info.onProgress(progress)//onProgress接收一个对象{ percent: 进度 }在进度条上显示
            //   } else if((progress.percent = 99) ) {
            //     progress.percent++
            //     info.onProgress(progress)
            //   } else if (progress.percent=100){
            //     clearInterval(intervalId)
            //   }
            // }, 100)
            info.onSuccess(e, info.file);
          }
        })
      // }
    },
    
    removeFile(){
      this.fileSrc = '';
      this.fileName = '';
      this.fileList = [];
    },
    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
      if (!isJpgOrPng) {
        this.$message.error('请上传正确格式图片文件!');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error('图片内存大小需在2MB之内');
      }
      return isJpgOrPng && isLt2M;
    },
    // 获取资质列表
    getcertifList(){
      this.$ajax({
        url:'/hxclass-pc/user-certif/certif-temp/select/option',
        method:'post'
      }).then(res=>{
        if(res.code && res.success){
          this.certifList = res.data;
          
          // 进阶默认选初阶 高阶选进阶
          let tempId = null;
          this.certifList.map(item=>{
            if(this.courseId == 4) {
              if(item.name == 'VB-MAPP评估培训合格证书（初阶）') {
                this.type = item.tempId;
              }
            }else if(this.courseId == 40){
              if(item.name == 'VB-MAPP评估培训合格证书（进阶）') {
                this.type = item.tempId;
              }
            }
          })
        }
      })
    },
    // 获取提示信息
    getTitleMessage(){
      this.$ajax({
        url:'/hxclass-pc/course/course-buy-audit/buy-prompt-content',
        method:'get',
        params:{
          courseId:this.courseId
        }
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.titleMessage = res.message;
        }
      })
    },
  },
  // 生命周期-实例创建完成后调用
  created () {
    this.getcertifList();
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
    qualificationvisible(newVal,oldVal){
      this.getTitleMessage();
    }
  }
}
</script>

<style lang="less" scoped>
  *{box-sizing: border-box;}
  /deep/.ant-modal-footer {
    display: none;
  }
  .uploadFile{
    cursor: pointer;
    margin-top: 12px;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 21px;
    letter-spacing: 1px;
  }
  .avatar-uploader{
    width: 305px;
    height: 229px;
    /deep/.ant-upload-select-picture-card{
      width: 305px;
      height: 229px;
    }
    img{
      max-width: 305px;
      max-height: 229px;
    }
  }
  .uploadStyle{
    img{
      width: 100px;
      height: 86px;
    }
    p{
      margin-top: 17px;
      font-size: 18px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 21px;
    }
  }
  .submitTitle{
    font-size: 20px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 23px;
    margin-bottom: 28px;
  }
  .modal_box {
    .box_title {
      text-align: center;
      font-size: 20px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 23px;
      margin-top: 8px;
    }
    .box_con {
      margin-top: 32px;
      .warnMess{
        display: flex;
        padding-bottom: 12px;
        margin-bottom: 24px;
        border-bottom: 1px solid #EBEBEB;
        img{
          width: 17px;
          height: 17px;
          margin-right: 10px;
          margin-top: 4px;
        }
        p{
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 25px;
        }
      }
      .formItem{
        display: flex;
        margin-bottom: 20px;
        .title{
          width: 108px;
          font-size: 16px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 19px;
        }
      }
      .formItem:nth-child(5){
        margin: 0;
      }
    }
    .box_button {
      display: flex;
      justify-content: space-between;
      margin-top: 47px;
      margin-bottom: 23px;
      padding: 0 136px;
      .all-btn-blue, .all-btn-small {
        width: 113px;
        height: 36px;
        line-height: 36px;
        font-size: 14px;
      }
    }
  }
  
  .load{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
</style>
