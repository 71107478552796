<template>
  <div>
    <!-- 全局提示框 -->
    <a-modal
      :dialog-style="{ top: '100px' }"
      :visible="isEquityShow"
      width="580px"
      @cancel="handleCancel"
    >
      <div class="modal-content">
        <p class="h4">购买指定课程赠与中国残疾人康复协会个人会员的通知</p>
        <p class="p name" >（针对2023年新购买课程的学员，用于购买课程时的说明）</p>
        <p class="p name" >为发展残疾人康复事业，壮大康复工作队伍，提升康复服务质量，经与中国残疾人康复协会商议，自2023年开始，在华夏云课堂购买由中国残疾人康复协会颁发证书的课程时，将赠与中国残疾人康复协会个人会员资格，具体规则为：</p>
        <p class="p" >1.会员有效期规则：</p>
        <p class="content" >1）华夏云课堂用户，每购买一门指定课程后，赠与一年有效期中国残疾人康复协会个人会员，在会员有效期内可享受中国残疾人康复协会个人会员权益（个人会员权益见：附表）。</p>
        <p class="content" style="margin-bottom:5px">2）通过购买指定课程获得中国残疾人康复协会个人会员有效期最高累计三年，有效期从第一次购买课程时间为起始时间。</p>
        <p class="tips" style="margin-bottom:10px">举例：一名华夏云课堂学员，在2023年1月1日买指定课程，获得会员有效期为1年，2023年12月31日到期；在2023年3月3日再次购买了一门指定课程，会员延期1年，即延期2024年12月31日；在2024年4月5日再次购买指定课程，会员延期1年，即延期2025年12月31日。</p>
        <p class="p" >2.购买课程规则：</p>
        <p class="content" style="margin-bottom:10px">指定课程名录：《孤独症康复教育上岗培训课程》、《智力障碍康复教育上岗培训课程》、《VB-MAPP评估初阶培训课程》、《VB-MAPP评估进阶培训课程》、《卡罗来纳特殊教育评估培训课程CCPSN》、《卡罗来纳特殊教育评估培训课程CCITSN》、《孤独症儿童言语技能培训课程》。</p>
        <p class="content" style="margin-bottom:10px;font-weight:bold;">注：如指定课程名录有更新，请您关注华夏云课堂相关通知。</p>
        <p class="p" >附表：《中国残疾人康复协会个人会员权利和义务》</p>
        <p class="p" >摘自：《中国残疾人康复协会个人会员注册须知》</p>
        <p class="p"  style="margin-bottom:10px">来源：https://www.cncard.org.cn/vip/personageNotice</p>
        <p class="p" >一、个人会员的权利</p>
        <p class="content" >1.有选举权、被选举权和表决权；</p>
        <p class="content" >2.参选分支机构委员、常务委员、副主任委员和主任委员；</p>
        <p class="content" >3.对协会工作提出批评、建议和监督；</p>
        <p class="content" >4.获得使用“中国残疾人康复协会会员”名称作为个人荣誉和从业、社会交往的资质；</p>
        <p class="content" >5.优惠参加协会举办的国内外有关学术交流、专题会议、继续教育、专业培训等活动；</p>
        <p class="content" >6.优惠征订协会主办或出版的报刊书籍和学术资料；</p>
        <p class="content" >7.享有协会举办的评奖活动以及分支机构举办优秀论文和优秀会员的评选；</p>
        <p class="content" style="margin-bottom:10px">8.入会自愿、退会自由。</p>
        <p class="p" >二、个人会员的义务</p>
        <p class="content" >1.遵守国家法律法规和制度，遵守本会章程，执行协会决议；</p>
        <p class="content" >2.接受协会监督、管理、依法开展业务活动；</p>
        <p class="content" >3.遵守职业道德、维护本会的合法权益和会员的声誉；</p>
        <p class="content" >4.积极参与协会及其分支机构的工作和活动，完成协会委托的工作；</p>
        <p class="content" >5.向协会反映情况，提供相关资讯；</p>
        <p class="content" >6.缴纳会费。</p>
        <div class="btn-foot">
          <p  class="all-btn-small" @click="handleCancel">我知道了</p>
        </div>
      </div>
      <template #footer></template>
    </a-modal>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: [
    'isEquityShow',  // 控制弹窗显示/隐藏  必填
  ],
  // 数据对象
  data () {
    return {}
  },
  // 事件处理器
  methods: {
    handleCancel() {
      this.$emit('closeEvent')
    },
  },
  // 生命周期-实例创建完成后调用
  created () {
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.modal-content {
  height: 100%;
  padding: 0 24px;
  overflow-y: scroll;
  .tips {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 23px;
    color: @theme;
    img {
      margin-right: 7px;
    }
  }
  .input {
    height: 40px;
    margin-top: 20px;
  }
  .h4{
    font-size: 18px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 27px;
    text-align: center;
    margin-bottom: 8px;
  }
  .p{
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    font-size: 16px;
    line-height: 27px;
  }
  .name{
    text-indent: 2em;
    margin-bottom: 10px;
  }
  .tips{
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 24px;
  }
  .content{
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 24px;
  }
  .btn-foot {
    display: flex;
    justify-content: center;
    width: 340px;
    margin: 0 auto;
    margin-top: 30px;
    .cancel {
      text-align: center;
      line-height: 36px;
      border-radius: 18px;
      width: 113px;
      height: 36px;
      color: @theme;
      border: 1px solid @theme;
      cursor: pointer;
    }
    .all-btn-small {
      border-radius: 18px;
      width: 113px;
      height: 36px;
      line-height: 36px;
      .anticon {
        margin-left: 10px;
      }
    }
    p{
      margin: 0 30px;
    }
  }
}
/deep/ .ant-modal-footer {
  display: none;
}
/deep/ .ant-modal {
  top: 260px;
}
/deep/.ant-modal-body{
  height: 700px;
  padding: 48px 0 24px;
}
/* 设置滚动条的样式 */
.modal-content::-webkit-scrollbar {
  width:4px;
}
/* 滚动槽 */
.modal-content::-webkit-scrollbar-track {
  -webkit-box-shadow:inset006pxrgba(0,0,0,0.3);
  border-radius:0;
  background:rgba(255, 255, 255, 0.1);
}
/* 滚动条滑块 */
.modal-content::-webkit-scrollbar-thumb {
  border-radius:10px;
  background:rgba(246,246,252,1);
  -webkit-box-shadow:inset006pxrgba(0,0,0,0.2);
}
</style>
